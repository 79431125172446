<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="900" persistent>
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':'Add'}`}} visitor
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>

                        <v-col cols="12">
                            <v-select label="Categories"
                                      :items="visitor_categories"
                                      item-text="title"
                                      item-value="id"
                                      @change="checkCategoryChange"
                                      :loading="categoryLoading"
                                      v-model="visitor.visitor_category_id" outlined dense>
                            </v-select>
                            <span class="text-danger" v-if="$v.visitor.visitor_category_id.$error">Category is required</span>
                        </v-col>
                        <!-- <v-col cols="12" v-if="selectedCategory  && selectedCategory[0].description">
                            <p v-html="selectedCategory[0].description"></p>
                        </v-col> -->
                        <v-col cols="6">
                            <v-text-field label="First name" v-model="visitor.first_name" outlined dense>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.visitor.first_name.$error">This information is required</span>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Last name" v-model="visitor.last_name" outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Email" v-model="visitor.email" outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="Phone" v-model="visitor.phone" outlined dense>
                            </v-text-field>
                        </v-col>
                        <v-col cols="col-6">
                            <v-autocomplete
                                    v-model="visitor.contact_point"
                                    :items="users"
                                    :loading="isBusy"
                                    outlined
                                    dense
                                    clearable
                                    :search-input.sync="searchName"
                                    item-text="display_text"
                                    item-value="full_name"
                                    label="Contact point user CRN or email"
                            >
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.personal_id }} - {{ data.item.full_name }}- {{ data.item.email }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <div class="col-6">
                        <v-select :loading="programLoading" outlined dense :items="programs" v-model="visitor.program_id"
                                  item-text="title"
                                  clearable
                                  item-value="id" label="Select program">
                            <template slot="selection" slot-scope="data">
                                <!-- HTML that describe how select should render selected items -->
                                {{ data.item.title }} - {{ data.item.title_text }}
                            </template>
                            <template slot="item" slot-scope="data">
                                <!-- HTML that describe how select should render items when the select is open -->
                                {{ data.item.title }} - {{ data.item.title_text }}
                            </template>
                        </v-select>
                        <!--              <span class="text-danger" v-if="$v.push_notification.single_device_token.$error">Type is required</span>-->
                        </div>
                        <v-col cols="12">
                            <label for="description">Purpose of visit</label>
                            <ckeditor id="description" v-model="visitor.description"
                                      :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.visitor.description.$error">This information is required</span>

                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <datetime class="border-date"
                                      value-zone="Asia/Kathmandu"
                                      placeholder="Event Start DateTime"
                                      :minute-step="15"
                                      format="yyyy-MM-dd hh:mm:ss"
                                      use12-hour
                                      type="datetime"
                                      v-model="visitor.entry_datetime">
                                <label for="leave_from" slot="before"><i class="fas fa-calendar-alt"></i></label>
                            </datetime>
<!--                            <v-menu-->
<!--                                    v-model="leave_from"-->
<!--                                    :close-on-content-click="false"-->
<!--                                    :nudge-right="40"-->
<!--                                    transition="scale-transition"-->
<!--                                    offset-y-->
<!--                                    min-width="auto"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-text-field-->
<!--                                            v-model="visitor.entry_datetime"-->
<!--                                            label="Entry Date Time"-->
<!--                                            append-icon="mdi-calendar"-->
<!--                                            readonly-->
<!--                                            outlined-->
<!--                                            dense-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on"-->
<!--                                    ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-date-picker-->
<!--                                        :min="today"-->
<!--                                        v-model="visitor.entry_datetime"-->
<!--                                        @input="leave_from = false"-->
<!--                                ></v-date-picker>-->
<!--                            </v-menu>-->
                            <span class="text-danger" v-if="$v.visitor.entry_datetime.$error">This information is required</span>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <datetime class="border-date"
                                      value-zone="Asia/Kathmandu"
                                      placeholder="Event start datetime"
                                      :minute-step="15"
                                      format="yyyy-MM-dd hh:mm:ss"
                                      use12-hour
                                      type="datetime"
                                      v-model="visitor.exit_datetime">
                                <label for="leave_from" slot="before"><i class="fas fa-calendar-alt"></i></label>
                            </datetime>
<!--                            <v-menu-->
<!--                                    v-model="leave_to"-->
<!--                                    :close-on-content-click="false"-->
<!--                                    :nudge-right="40"-->
<!--                                    transition="scale-transition"-->
<!--                                    offset-y-->
<!--                                    min-width="auto"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <v-text-field-->
<!--                                            v-model="visitor.exit_datetime"-->
<!--                                            label="Exit Date Time"-->
<!--                                            append-icon="mdi-calendar"-->
<!--                                            readonly-->
<!--                                            outlined-->
<!--                                            dense-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on"-->
<!--                                    ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-date-picker-->
<!--                                        :min="today"-->
<!--                                        v-model="visitor.exit_datetime"-->
<!--                                        @input="leave_to = false"-->
<!--                                ></v-date-picker>-->
<!--                            </v-menu>-->
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox v-model="visitor.follow_up_required" label="Follow up required"></v-checkbox>
                        </v-col>
                        <v-col
                                v-if="visitor.follow_up_required"
                        cols="12"
                        sm="6"
                        md="6"
                        >
                        <v-menu
                                v-model="leave_to"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="visitor.follow_up_date"
                                        label="Follow up date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    :min="today"
                                    v-model="visitor.follow_up_date"
                                    @input="leave_to = false"
                            ></v-date-picker>
                        </v-menu>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate" v-if="checkIsAccessible('visitor','create')"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import VisitorCategoryService from "@/core/services/visitor/visitor-category/VisitorCategoryService";
    const visitorCategoryService = new VisitorCategoryService();
    import VisitorService from "@/core/services/visitor/visitor/VisitorService";
    const visitorService = new VisitorService();
    import UserService from "@/core/services/user/UserService";
    const userService = new UserService();
    import ProgramService from "@/core/services/level/program/ProgramService";
    const programService = new ProgramService();

    import {Datetime} from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'
    export default {
        name: 'create-visitor-category',
        validations: {
            visitor: {
                first_name: {required},
                visitor_category_id: {required},
                description: {required},
                entry_datetime: {required},

            }
        },
        components: {
            datetime: Datetime
        },
        data() {
            return {
                users: [],
                academic_class: [],
                visitor_categories: [],
                dialog: false,
                edit: false,
                isBusy: false,
                attrs: false,
                programLoading :false,
                programs :[],
                today: new Date().toISOString().substr(0,10),
                selectedCategory: null,
                leave_from: false,
                leave_to: false,
                categoryLoading: false,
                applicable_for: [
                    {
                        name: 'Student',
                        value: 'student'
                    },
                    {
                        name: 'Teachers',
                        value: 'teacher'
                    }, {
                        name: 'Staff',
                        value: 'staff'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                editorConfig: {
                  versionCheck: false,
                    toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
                },
                visitor: {
                    first_name: null,
                    last_name: null,
                    phone: null,
                    email: null,
                    visitor_category_id: null,
                    description: null,
                    entry_datetime: null,
                    exit_datetime:  null,
                    contact_point: null,
                    program_id: null,
                    follow_up_date: null,
                    follow_up_required: false,
                    is_active: false,
                },
                searchName: null,
                search: {
                    name: '',
                    limit: 5,
                },
            };
        },
        mounted() {

        },
        watch: {
            searchName(newValue, oldValue) {
                this.search.name = newValue;
                console.log(this.search.name)
                // Lazily load input items
                this.getUsers();

            },
        },
        methods: {
            getUsers() {
                this.isBusy = true;
                userService.paginate(this.search).then(response => {
                    this.isBusy = false;
                    this.users = response.data.data;
                    this.users.map(ele => {
                        if (ele.personal_id && ele.personal_id != undefined && ele.personal_id != null) {
                            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`
                            this.users.push(ele)
                        }
                    })
                    // this.page = response.data.meta.current_page;
                    // this.total = response.data.meta.total;
                    // this.perPage = parseInt(response.data.meta.per_page);
                    // alert(this.length)
                });
            },
            checkCategoryChange(){
                 this.selectedCategory = this.visitor_categories.filter(visitor => {
                    return visitor.id === this.visitor.visitor_category_id;
                })
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__getSingle(id)
                }else{
                    this.visitor.entry_datetime = this.$moment().toISOString()
                    this.visitor.exit_datetime = this.$moment().toISOString()
                }
                this.visitor.is_active = true;
                this.dialog = true;
                this.__getCategory();
                this.getPrograms();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __getSingle(id) {
                visitorService.show(id).then(response => {
                    this.visitor = response.data.visitor
                    // if (this.visitor.entry_datetime)
                    //     this.visitor.entry_datetime = this.$moment(this.visitor.entry_datetime).toISOString();
                    // if (this.visitor.exit_datetime)
                    //     this.visitor.exit_datetime = this.$moment(this.visitor.exit_datetime).toISOString();
                })
            },
            __getCategory() {
                this.categoryLoading = true;
                visitorCategoryService.paginate().then(response => {
                    this.visitor_categories = response.data.data
                }).catch(error=>{
                    console.log(error)
                }).finally(()=>{
                    this.categoryLoading = false;
                })
            },
            getPrograms() {
                this.programLoading = true;
                programService.all().then(response => {
                    this.programs = response.data;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.programLoading = false;

                });
            },

            generateFd() {
                // console.log(this.visitor)
                let fd = new FormData();
                for (let key in this.visitor) {
                    if (key == "is_half" && this.visitor["is_half"]) {
                        fd.append("is_half", this.visitor ? 1 : 0);
                    } else {
                        if (this.visitor[key] != null) {
                            fd.append(key, this.visitor[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {

                this.$v.visitor.$touch();
                if (this.$v.visitor.$error) {
                    setTimeout(() => {
                        this.$v.visitor.$reset();
                    }, 3000);
                } else {
                        let fd = this.generateFd();
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                visitorService
                    .update(this.visitor.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            __create(fd) {
                this.isBusy = true;
                visitorService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.visitor.$reset();
                this.edit = false;
                this.visitor = {
                    first_name: null,
                    last_name: null,
                    phone: null,
                    email: null,
                    visitor_category_id: null,
                    description: null,
                    entry_datetime: null,
                    exit_datetime:  null,
                    contact_point: null,
                    program_id: null,
                    follow_up_date: null,
                    follow_up_required: false,
                };
                this.hideModal();
                this.$emit("refresh_visitor");
            }
        }
    };
</script>
<style scoped>
    .border-date{
        border: 1px solid #9E9E9E ;
        border-radius: 4px;
        padding: 8px 8px 3px 8px;
    }
</style>
