<template>
    <div>
        <v-sheet height="64">
            <v-toolbar
                    flat
            >
                <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                >
                    Today
                </v-btn>
                <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                >
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                        fab
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                >
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                        bottom
                        right
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                            <span>{{ typeToLabel[ctype] }}</span>
                            <v-icon right>
                                mdi-menu-down
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="ctype = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="ctype = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="ctype = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="ctype = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
        </v-sheet>
        <v-sheet height="400">
            <v-calendar
                    ref="calendar"
                    v-model="focus"
                    :type="ctype"
                    :event-overlap-mode="mode"
                    :events="items"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    event-text-color="white"
                    category-show-all
                    :event-more="more"
                    @click:event="showEvent"
            ></v-calendar>
            <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
            >
                <v-card
                        color="grey lighten-4"
                        min-width="350px"
                        flat
                >
                    <v-toolbar
                            :color="selectedEvent.color"
                            dark
                    >

                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>

                    </v-toolbar>
                    <v-card-text>
                        <h5>{{selectedEvent.status || 'Pending'}}</h5>
                        <span v-html="selectedEvent.content"></span>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                text
                                color="secondary"
                                @click="selectedOpen = false"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-sheet>
    </div>
</template>

<script>


    export default {
        name: "EventComponents",
        props:['leave_requests'],
        data() {
            return {
                selectedEvent: {},
                selectedElement: null,
                selectedOpen: false,
                more: true,
                focus: '',
                ctype: 'month',
                mode: 'column',
                types: ['month', 'week', 'day', '4day'],
                typeToLabel: {
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    '4day': '4 Days',
                },
                events:[

                ],
            }
        },
        mounted() {

        },
        methods: {
            viewDay ({ date }) {
                this.focus = date
                this.ctype = 'day'
            },
            getEvents() {
                blogService
                    .paginate({
                        type:'event'
                    })
                    .then(response => {
                        this.events = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },

            prev() {
                this.$refs.calendar.prev()
            },
            next() {
                this.$refs.calendar.next()
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            setToday() {
                this.focus = ''
            },
            showEvent({nativeEvent, event}) {
                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    setTimeout(() => {
                        this.selectedOpen = true
                    }, 10)
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    setTimeout(open, 10)
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
        },
        computed: {
            items() {
                const items = [];

                this.leave_requests.forEach((item) => {
                    items.push({
                        name: item.title,
                        start: item.leave_start_date,
                        end: item.leave_end_date,
                        content: item.description,
                        color: "red",
                        timed: false,
                    })
                })
                return items;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },
    }
</script>

<style scoped>

</style>