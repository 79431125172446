<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card ">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4>Manage visitors</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Visitors
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('visitor', 'create')" @click="__add"
                                        class="mt-4 btn btn-primary" style="color: #fff">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add visitor
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="4">
                                    <v-select
                                        outlined
                                        dense
                                        v-model="search.category_id"
                                        label="Select category"
                                        placeholder="Search by category"
                                        :items="categories"
                                        item-text="title"
                                        item-value="id"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="auto"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="menu"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                append-icon="mdi-calendar"
                                                clearable
                                                dense
                                                label="Search by date"
                                                outlined
                                                readonly
                                                v-on:keyup.enter="searchVisitors"
                                                v-bind="attrs"
                                                v-model="search.entry_date"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            @input="menu = false"
                                            v-model="search.entry_date"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click="searchVisitors()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader type="table-thead" v-if="loading">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table mt-3" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Visitor name</th>
                                        <th class="px-3 wrap-column">Date</th>
                                        <th class="px-3 wrap-column">Category</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="visitors.length > 0" v-for="(visitor, index) of visitors" :key="visitor.id">
                                        <td scope="row" class="px-3 wrap-column">
                                            <router-link :to="{ name: 'visitor-summary', params: { visitor_id: visitor.id } }">
                                                {{ visitor.first_name }} {{ visitor.last_name }}
                                            </router-link>
                                            <i class="fas fa-circle ml-2"
                                                :class="visitor.is_active ? 'dot-active' : 'dot-inactive'"></i>

                                        </td>
                                        <td scope="row" class="px-3 wrap-column">
                                            <span class="font-weight-medium">
                                                {{ visitor.entry_datetime | moment("Do MMMM YYYY") }}
                                            </span>
                                            <small v-if="visitor.leave_end_date">
                                                <span class="font-weight-medium"> - {{ visitor.exit_datetime | moment("Do MMMM
                                                                                                YYYY")}}</span>
                                            </small>
                                        </td>
                                        <td class="px-3 wrap-column pt-3">
                                            <span v-if="visitor.category">
                                                <span class="badge badge-primary ml-2 text-capitalize">
                                                    {{ visitor.category }}
                                                </span>

                                            </span>
                                        </td>
                                        <td class="px-3 wrap-column pt-3">
                                            <span class="badge" :class="visitor.status ? 'badge-success' : 'badge-warning'">
                                                {{ visitor.status ? visitor.status : 'Pending' }}
                                            </span>
                                        </td>

                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">

                                                    <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                                                    <!--                                                    <a href="#" class="navi-link" @click="detail(visitor.id)">-->
                                                    <!--                                                            <span class="navi-icon">-->
                                                    <!--                                                              <i class="fas fa-eye"></i>-->
                                                    <!--                                                            </span>-->
                                                    <!--                                                      <span class="navi-text"> View </span>-->
                                                    <!--                                                    </a>-->
                                                    <!--                                                  </b-dropdown-text>-->
                                                    <b-dropdown-text tag="div" class="navi-item"
                                                        v-if="checkIsAccessible('visitor', 'edit')">
                                                        <a href="#" class="navi-link" @click="__edit(visitor.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item"
                                                        v-if="checkIsAccessible('visitor', 'delete')">
                                                        <a href="#" class="navi-link" @click="__delete(visitor.id)">
                                                            <span class="navi-icon">
                                                                <i class="fas fa-trash"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete</span>
                                                        </a>
                                                    </b-dropdown-text>

                                                </div>
                                                <!--end::Navigation-->
                                            </b-dropdown>

                                        </td>
                                    </tr>
                                    <tr v-if="visitors.length == 0">
                                        <td class="px-3 text-center" colspan="10">
                                            <strong>Data not available</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination v-if="visitors.length > 0" class="pull-right mt-7" @input="__get" v-model="page"
                                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
                        </div>

                    </div>
                    <v-dialog v-model="leave_detail_dialog" max-width="900">
                        <v-card v-if="leave_detail">

                            <v-card-title>
                                {{ leave_detail.first_name }}
                            </v-card-title>

                            <v-card-text>
                                <div class="p-2">
                                    <strong>
                                        Entry Time : {{ leave_detail.entry_datetime | moment("Do MMMM YYYY") }}
                                    </strong>
                                    <strong v-if="leave_detail.leave_end_date">
                                        - Exit Time :- {{ leave_detail.exit_datetime | moment("Do MMMM YYYY") }}
                                    </strong>
                                </div>
                                <v-row>
                                    <v-col cols="6">
                                        Email : {{ leave_detail.email || 'N/A' }}
                                    </v-col>
                                    <v-col cols="6">
                                        Phone : {{ leave_detail.phone || 'N/A' }}
                                    </v-col>
                                    <v-col cols="6">
                                        Contact Point : {{ leave_detail.contact_point || 'N/A' }}
                                    </v-col>
                                    <v-col cols="6">
                                        Program : {{ leave_detail.program || 'N/A' }}
                                    </v-col>
                                    <v-col cols="4">
                                        Visit Category :
                                        <span v-if="leave_detail.category">
                                            <span class="badge badge-primary ml-2 text-capitalize">
                                                {{ leave_detail.category }}
                                            </span>

                                        </span>
                                    </v-col>
                                    <v-col cols="4">
                                        Follow Up Requred :
                                        <span v-if="leave_detail.follow_up_required">
                                            <span class="badge  ml-2 text-capitalize"
                                                :class="leave_detail.follow_up_required ? 'badge-success' : 'badge-danger'">
                                                {{ leave_detail.follow_up_required ? 'Yes' : 'No' }}
                                            </span>

                                        </span>
                                    </v-col>
                                    <v-col cols="4" v-if="leave_detail.follow_up_required">
                                        Follow Up Date :
                                        <span v-if="leave_detail.follow_up_date">
                                            {{ leave_detail.follow_up_date | moment("Do MMMM YYYY") }}

                                        </span>
                                    </v-col>

                                </v-row>

                                <label for="">Purpose of Visit</label>
                                <p v-html="leave_detail.description"></p>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select outlined dense :items="statuses" item-value="value" item-text="title"
                                            v-model="leave_detail.status" label="Status">

                                        </v-select>

                                    </v-col>

                                    <v-col cols="12">
                                        <div class="text-right float-right">
                                            <v-btn depressed @click="__reset" class="text-gray btn btn-standard  ">
                                                Cancel
                                            </v-btn>
                                            <v-btn depressed :loading="isBusy" @click="__update"
                                                class="text-white ml-2 btn btn-primary ">
                                                Save
                                            </v-btn>
                                        </div>

                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-dialog>
                    <create-and-update ref="visitor" @refresh_visitor="__get"></create-and-update>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import leaveCalender from "./leaveCalender";

import VisitorService from "@/core/services/visitor/visitor/VisitorService";
import VisitorCategoryService from "@/core/services/visitor/visitor-category/VisitorCategoryService";
const visitorService = new VisitorService();
const visitorCategoryService = new VisitorCategoryService();

export default {
    name: "Visitor",
    display: "Table",
    order: 8,
    components: {
        CreateAndUpdate,
        leaveCalender
    },
    data() {
        return {
            statuses: [
                { title: 'Approved', value: "approved" },
                { title: 'Decline', value: "declined" },
                { title: 'Pending', value: "pending" },
            ],
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            visitors: [],
            page: null,
            leave_detail: null,
            loading: false,
            attrs: false,
            isBusy: false,
            leave_detail_dialog: false,
            perPage: null,
            total: null,
            search: {
                // active: "active",
                // is_parent: "null"
                category_id: null,
                entry_date: null
            },
            categories: [],
            menu: false
        };
    },
    mounted() {
        this.__get();
        this.getVisitorCategories();
    },
    computed: {},
    methods: {
        getApplicable_users(users) {
            return users.split(',');
        },
        searchVisitors(){
            this.page = 1;
            this.__get();
        },
        getVisitorCategories(){
            visitorCategoryService
            .all()
            .then((res) => {
                this.categories = res.data.data;
            })
            .catch((err) => {

            });
        },
        __get() {
            this.loading = true;
            visitorService
                .paginate(this.search)
                .then(response => {
                    this.visitors = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                })
                .catch(error => {
                    // console.log(error);
                }).finally(() => {
                    this.loading = false;

                });
        },
        __add() {
            this.$refs["visitor"].showModal();
        },
        __edit(id) {
            this.$refs["visitor"].showModal(id);
        },

        __detail(leave) {
            this.leave_detail_dialog = true;
            this.leave_detail = leave;

        },
        __reset() {
            this.leave_detail_dialog = false;
            this.leave_detail = {};

        },
        __update() {
            let fd = this.leave_detail;
            this.isBusy = true;
            visitorService
                .update(this.leave_detail.id, fd)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.__reset();
                    this.__get();
                })
                .catch(error => {
                    this.isBusy = false;
                    // console.log(error);
                }).finally(() => {
                    this.isBusy = false;
                });
        },

        generateFd() {
            // console.log(this.leave_request)
            let fd = new FormData();
            for (let key in this.leave_detail) {
                if (key == "is_half" && this.leave_detail["is_half"]) {
                    fd.append("is_half", this.leave_detail ? 1 : 0);
                } else {
                    if (this.leave_detail[key] != null) {
                        fd.append(key, this.leave_detail[key]);
                    }
                }
            }
            return fd;
        },
        __delete(id) {
            this.$confirm({
                message: `Are you sure?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        visitorService
                            .delete(id)
                            .then(response => {
                                this.$snotify.success('Successfully Deleted')
                                this.__get();
                            })
                            .catch(error => {
                                //console.log(error);
                            });
                    }
                }
            });
        },


    }
};
</script>
<style scoped></style>
